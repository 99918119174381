// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Headroom from './headroom'
window.Headroom = Headroom

import Rails from "@rails/ujs";
window.Rails = Rails;

import $ from 'jquery';

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');

require("bootstrap");

const mediaQueryReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)");

$( document ).ready(function() {
  $("a.splash").click(function(e) {
    if(!$("a.splash").hasClass("flushed")) {
      e.preventDefault();
      flushSplash();
    }
  });
  
  document.body.onkeyup = function(e){
    if(!$("a.splash").hasClass("flushed")) {
      flushSplash();
    }
    else {
      window.location.href = redirectURL;
    }
  }
  
  function flushSplash() {
    // Check if the media query matches or is not available.
    if (mediaQueryReducedMotion.matches) {
       window.location.href = redirectURL;
    } else {
      $("a.splash").addClass("flushed");
      
      flushSound.play();
      
      setTimeout(function () {
         window.location.href = redirectURL;
      }, 5000);
    }
  }
  
  $("[data-action='welcomeSkipToFrame']").click(function(e) {
    e.preventDefault();
    
    $('html, body').animate(
      {
        scrollTop: ($("#main-content-first-frame").offset().top) - 170,
        easing: 'swing',
      },
      500,
      'linear'
    )
  });
  
  if (typeof upcomingFeed !== 'undefined' && upcomingFeed != '') {
    console.log(upcomingFeed);
    fetch(upcomingFeed)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Error retrieving data.');
      }
    })
    .then(data => listUpcomingGigs(data))
    .catch((error) => {
      $(".loading-spinner").html("<i class='fas fa-exclamation-circle'></i> " + error);
    });
  }
  
  var CookieDate = new Date;
  CookieDate.setFullYear(CookieDate.getFullYear() +1);

  $( ".site-header .alert" ).click(function() {
    $(this).slideUp("fast");
  });


  $( ".site-header .collapse-btn" ).click(function() {
    $( ".site-header" ).toggleClass( "menu-open" )
    $(this).attr('aria-expanded', function (i, attr) {
      return attr == 'true' ? 'false' : 'true'
    });
  });

  $("#albums").sortable({
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });

    }
  });

  $("#links").sortable({
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
  
    }
  });
  
  $("#media").sortable({
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
  
    },
    items: "li:not(.ui-state-disabled)"
  });

  $(".label-select-row label").click(function() {
    $(this).children("input").focus();
  });

  const pressKitAppearance = getCookie('press_kit_appearance');

  if(pressKitAppearance == 'light') {
    addAppearanceClassesToPressKit();
    $(".presskit-frame #cbLightMode").prop('checked', true);
  }

  $(".presskit-frame #cbLightMode").click(function() {

    if($(this).prop('checked')) {
      addAppearanceClassesToPressKit();
      document.cookie = 'press_kit_appearance=light;expires='+CookieDate.toUTCString()+';path=/';
    }
    else {
      removeAppearanceClassesFromPressKit();
      document.cookie = 'press_kit_appearance=dark;expires='+CookieDate.toUTCString()+';path=/';
    }
  });
  
  function addAppearanceClassesToPressKit() {
    $(".presskit-frame").addClass("bg-light");
    $(".presskit-frame").addClass("presskit-frame-light");
  }

  function removeAppearanceClassesFromPressKit() {
    $(".presskit-frame").removeClass("bg-light");
    $(".presskit-frame").removeClass("presskit-frame-light");
  }

});

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

function listUpcomingGigs(data) {
  if(data) {
    // console.log(data);
    $(".upcoming-shows-list .loading-spinner").hide();
    let numberOfGigs = 0;
    $.each(data, function(i, gig) {
      let gigEventImage = $("");
      if(gig.event_image_url || gig.cover_image_url) {
        numberOfGigs++;
        gigEventImage = $(`
          <a href="${gig.url}" class="event-image-container">
            <img class='event-image' src='${ gig.event_image_url ? gig.event_image_url : ( gig.cover_image_url ? gig.cover_image_url : "" ) }' alt='Artwork for ${gig.title}'>
          </a>
        `);
      }
      let gigDate = new Date(gig.start_time);
      
      gigDate = formatDate(gigDate);
      
      let gigStatus = "";
      
      switch (gig.status) {
        case 'postponed':
        gigStatus = "<span class='badge badge-warning text-dark'>Postponed</span> &nbsp;";
          break;
        case 'cancelled':
          gigStatus = "<span class='badge badge-danger'>Cancelled</span> &nbsp;";
          break;
        default:
          break;
      }
      
      let gigHtmlLink = $(`
        <div class='gig-info-block'>
          <div class="gig-info">
            <h3 class='h4'><a href="${gig.url}">${gig.title}</a></h3>
            <span class="gig-details">${gigStatus} ${gigDate} <br> ${gig.location}${ gig.country ? ", " + gig.country : ""}</span>
          </div>
        </div>
      `).prepend(gigEventImage);
      
      let gigTicketLink = $("");
      if(gig.ticket_url) {
        gigTicketLink = $(`<li class="action"><a class="btn btn-secondary" href="${gig.ticket_url}">Get Tickets</a></li>`);
      }
      let gigEventLink = $("");
      // if(gig.eventlink || gig.fburl) {
      //   let gigEventLinkSelect = ( gig.eventlink ? gig.eventlink : (gig.fburl ? gig.fburl : "") )
      //   gigEventLink = $(`<li class="action"><a class="btn btn-sm btn-secondary" href="${ gigEventLinkSelect }">Event Info</a></li>`);
      // }
      
      let gigButtons = $(`<ul class="actions"></ul>`).append(gigEventLink).append(gigTicketLink);
      
      let gigHtml = $("<li class='gig'></li>").attr('id', 'gig-' + gig.slug).append(gigHtmlLink).append(gigButtons);
      $(".upcoming-shows-list").append(gigHtml);
      
      // $(".upcoming-shows-list").append(`
      //   <li>
      //     <a href='${gig.url}' class='gig-info'>
      //       <h3 class='h5'>${gig.title}</h3>
      //       <span>${gig.date}</span>
      //     </a>
      //   </li>
      //   `);
    });
    if(numberOfGigs < 1) {
      $(".upcoming-shows-list").append("<li><i class='fas fa-info-circle'></i> No new gigs for yas yet.</li>");
    }
  }
}

function formatDate(d) {
  const year = d.getFullYear() // 2019
  const date = d.getDate() // 23''
  
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  
  const monthName = months[d.getMonth()]
  
  const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]
  
  const dayName = days[d.getDay()]
  
  const formatted = `${d.getHours()}:${String(d.getMinutes()).padStart(2, "0")} &nbsp; ${dayName} ${date} ${monthName} ${ new Date().getFullYear() == year ? "" : year }`
  
  return formatted
}